import React, { useEffect, useState } from 'react';
import { $http } from "../../instance/axios.instance"
import HeroHeader from '../../components/header/useHeroHeader';
import TwoSided from '../../components/others/two-sided';
import { appendScript } from '../../utils/appendScript'
import parse from 'html-react-parser';

const AdministrativeStructure = () => {

	const [data, setData] = useState();
	const reload = false;
	useEffect(() => {
		appendScript("assets/js/custom.js");
		async function fetchData() {
			try {
				const response = await $http.get("administrative-structure");
				setData(response.data.data);
			}
			catch (e) {
				console.log(e)
			}
		};
		fetchData();
	}, [reload]);

	return (
		<>
			<HeroHeader name={"Administrative Structure"} />
			<TwoSided>
				<div className="law-card-area">
					<div className="container">
						<div className="card-contant">
							<div className="row no-gutters align-text-item">
								{data && parse(data ? data.content : "")

								}

							</div>
						</div>
					</div>
				</div>
			</TwoSided>
		</>
	);
}

export default AdministrativeStructure