import React, { useState } from 'react';
import { Link } from 'react-router-dom';


function Footer() {
    const [year, setYear] = useState(new Date().getFullYear());

    return (
        <>
            <footer className="footer-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="footer-widget">
                                <div className="logo">
                                    <img src="assets/img/logo-white.png" alt="logo" />
                                </div>
                                <p>
                                    The Court of Appeal is the Penultimate Court in the hierarchy of Courts in Nigeria. Prior to its establishment, there had been a system of direct Appeals from the High Courts to the Federal Supreme Court, which had become the Nation’s Apex Court in 1963 following the abolition of appeals to the Judicial Committee of the Privy Council.
                                </p>

                                <ul className="footer-socials">
                                    <li>
                                        <a href="https://web.facebook.com/CourtofAppealNigeria" target="_blank">
                                            <i className="lab la-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/ngcourtofappeal?s=21&t=hNDI8br9whROkupiQ4v9qw" target="_blank">
                                            <i className="lab la-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://instagram.com/ngcourtofappeal?igshid=YmMyMTA2M2Y=" target="_blank">
                                            <i className="lab la-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UCW3tbN0XuoX5CwAOLjTbBfg" target="_blank">
                                            <i className="lab la-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-6">
                            <div className="footer-widget">
                                <h3>Quick Links</h3>

                                <ul className="footer-text">
                                    <li>
                                        <Link to="/">
                                            <i className="las la-star"></i>
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/history">
                                            <i className="las la-star"></i>
                                            Court History
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/news">
                                            <i className="las la-star"></i>
                                            News and Event
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/picture-category">
                                            <i className="las la-star"></i>
                                            Picture Gallery
                                        </Link>
                                    </li>
                                    <li><Link to="/structure"><i className="las la-star"></i>Structure of the Court</Link></li>
                                    <li><Link to="/past-chief-registrar-court-of-appeal"><i className="las la-star"></i>Past and Present Chief Registrars</Link></li>
                                    <li><Link to="/management-staff"><i className="las la-star"></i>Management</Link></li>
                                    
                                    <li><a href="https://ept.courtofappeal.gov.ng"><i className="las la-star"></i>EPT</a></li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-widget pl-50">
                                <h3>Court Links</h3>

                                <ul className="footer-text">
                                    <li><a href="http://supremecourt.gov.ng" target={"_blank"}><i className="las la-star"></i>Supreme Court of Nigeria</a></li>
                                    <li><a href="http://www.njcgov.org" target={"_blank"}><i className="las la-star"></i>National Judicial Council</a></li>
                                    <li><a href="http://nials.edu.ng" target={"_blank"}><i className="las la-star"></i>Nigerian Institute of Advanced Legal Studies</a></li>
                                    <li><a href="http://fjsc.gov.ng" target={"_blank"}><i className="las la-star"></i> Federal Judicial Service Commission</a></li>
                                    <li><a href="https://www.fhc.gov.ng" target={"_blank"}><i className="las la-star"></i> Federal High Court</a></li>
                                    <li><a href="https://nicn.gov.ng/" target={"_blank"}><i className="las la-star"></i>National Industrial Court of Nigerian</a></li>
                                    <li><a href="#" ><i className="las la-star"></i>Sharia Court of Appeal</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-widget">
                                <h3>Contact Info</h3>

                                <ul className="info-list">
                                    <li>
                                        <i className="las la-phone"></i>
                                        <a href="tel:+2349010331588">+2349010331588</a>
                                    </li>
                                    <li>
                                        <i className="las la-envelope"></i>
                                        <a href="mailto:info@courtofappeal.gov.ng"> hq@courtofappeal.gov.ng </a>
                                    </li>
                                    <li>
                                        <i className="las la-map-marker-alt"></i>
                                        Three Arms Zone, Shehu Shagari Way, Central Business District, FCT-Abuja.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="footer-bottom">
                <div className="container">
                    <p>Copyright @ {year}. The Court of Appeal. All Rights Reserved</p>
                </div>
            </div>

            <div className="go-top">
                <i className="las la-hand-point-up"></i>
            </div>
        </>
    );

}

export default Footer;
