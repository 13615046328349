import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { $http } from "../../instance/axios.instance"
import HeroHeader from '../../components/header/hero-header-small';
import { formatDate } from "../../utils/utils"
import { appendScript } from '../../utils/appendScript'
import { useAuth } from '../../context/user/user.context';
import { toast } from 'react-toastify';

const ViewJudgement = () => {

	const { user } = useAuth();

	function useQuery() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	let query = useQuery();
	let page = 1;
	let pageSize = 30;

	const [drdivision] = useState(user?.division?.id);
	const [caseNo, setCaseNo] = useState("");
	const [party, setParty] = useState("");
	const [si, setSi] = useState("");
	const [sm, setSm] = useState("");
	const [date, setDate] = useState("");
	const [loading, setLoading] = useState();
	const [meta, setMeta] = useState({});
	const [list, setList] = useState([]);

	async function handleSubmit(event) {
		try {
			event.preventDefault();
			page = 1;
			meta.pagination.page = 0
			LoadData()
		}
		catch (e) {
			console.log(e)
			setLoading(false)
		}
	};

	const del = async (id) => {
		if (confirm('Are you sure?')) {
			$http.delete(`judgements/${id}`, {
				headers: {
					'Authorization': `bearer ${localStorage.getItem("token")}`
				}
			}).then((e) => {
				setList(list.filter(x=>x.id != id))
				toast.success('Operation successful', {
					position: "bottom-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}).error((e) => {
				console.log(e)
			})
		}
	}
	
	const LoadData = async () => {
		try {
			if (page < 1 || page === meta.pagination?.page) {
				return;
			}

			setLoading(true)
			setList([]);
			let query = `division=${drdivision}&caseNo=${caseNo}&party=${party}&date=${date}&p=${page}&ps=${pageSize}`;
			const res = await $http.get("judgement/search?" + query);
			if (res) {
				setList(res.data.data);
				setMeta(res.data.meta)
				setLoading(false)
			}
		}
		catch (e) {
			console.log(e)
			setLoading(false)
		}
	}

	useEffect(() => {
		appendScript("assets/js/custom.js");
		LoadData();
	}, []);



	return (
		<>
			<HeroHeader />
			<div className="pt-50 pb-100">
				<div className="container">
					<div className="card-contant">
						<div className="row no-gutters">
							<div className='row'>
								<div className='col-md-9'>
									<h2>Judgments</h2>
								</div>
								<span className='col-md-3 mb-3'>
									<Link className="default-btn-one" style={{ 'float': "right" }} to='/admin/add-judgment'>Add Judgment</Link>
								</span>
							</div>
							<form onSubmit={handleSubmit}>
								<div className="row text-white-50 bg-dark pt-3 ">
									<div className="col-md-6">

										<div className="mb-3 row">
											<label for="inputPassword" className="col-sm-2 col-form-label">Parties</label>
											<div className="col-sm-10">
												<input type="text" onChange={(e) => setParty(e.target.value)} value={party} className="form-control" />
											</div>

										</div>

										<div className="mb-3 row">
											<label for="inputPassword" className="col-sm-2 col-form-label">Subject Matter</label>
											<div className="col-sm-10">
												<input type="text" onChange={(e) => setSm(e.target.value)} value={sm} className="form-control" />
												<button type="submit" className="default-btn-one mt-1">Search</button>
											</div>

										</div>

									</div>
									<div className="col-md-6">
										<div className="mb-3 row">
											<label for="inputPassword" className="col-sm-2 col-form-label">Appeal No.</label>
											<div className="col-sm-10">
												<input type="text" onChange={(e) => setCaseNo(e.target.value)} value={caseNo} className="form-control" />
											</div>
										</div>
										<div className="mb-3 row">
											<label for="inputPassword" className="col-sm-2 col-form-label">Date</label>
											<div className="col-sm-10">
												<input type="date" onChange={(e) => setDate(e.target.value)} value={date} className="form-control" />
											</div>
										</div>
										<div className="mb-3 row">
											<label for="inputPassword" className="col-sm-2 col-form-label">Issue</label>
											<div className="col-sm-10">
												<input type="text" onChange={(e) => setSi(e.target.value)} value={si} className="form-control" />
											</div>

										</div>

									</div>

								</div>
							</form>
							<div style={{ overflowX: 'scroll' }}>
								<table className="table mt-3 table-striped">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th width="10%">Date</th>
											<th>Appeal No.</th>
											<th>Parties</th>
											<th width="20%">Panel</th>
											<th >Subject Matter</th>
											<th >Issue</th>
											<th>View</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{!loading ? list.map((item, index) =>
											<tr key={index}>
												<td scope="row">{index + 1}</td>
												<td>{formatDate(item.date)}</td>
												<td>{item.file_no}</td>
												<td>{item.parties}</td>
												<td width="30%">{item.justice}</td>
												<td >{item.SubjectMatter}</td>
												<td >{item.SubstantialIssue}</td>
												<td>
													<a target="_blank" className='link' href={item.download_link}>Judgment (pdf)</a>
												</td>
												<td>
													<Link to={`/admin/add-judgment/${item.id}`} className='btn btn-success'>edit</Link>
												</td>
											</tr>
										) :
											<h2 className='loading_center'>...loading</h2>}
									</tbody>
								</table>
							</div>

							{
								meta.pagination?.pageCount > 1 ?
									<div className="col-lg-12 col-md-12">
										<ul className="pagination">
											{meta.pagination.page > 1 &&
												<>
													<li className="page-item">
														<a className="page-link" href="#" onClick={() => { page = meta.pagination.page - 1; LoadData() }} aria-label="Previous">
															<span aria-hidden="true">&laquo;</span>
														</a>
													</li>
													<li className="page-item" onClick={() => { page = meta.pagination.page - 1; LoadData() }}><a className="page-link" href="#">{meta.pagination.page - 1}</a></li>
												</>
											}
											<li className="page-item active"><a className="page-link" href="#">{meta.pagination.page}</a></li>
											{meta.pagination.page < meta.pagination.pageCount &&
												<>
													<li className="page-item" onClick={() => { page = meta.pagination.page + 1; LoadData() }}><a className="page-link" href="#">{meta.pagination.page + 1}</a></li>
													<li className="page-item">
														<a className="page-link" onClick={() => { page = meta.pagination.page + 1; LoadData() }} href="#" aria-label="Next">
															<span aria-hidden="true">&raquo;</span>
														</a>
													</li>
												</>
											}
										</ul>
									</div>
									: null
							}

						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ViewJudgement