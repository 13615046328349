
import React, { useEffect, useState } from 'react';
import { $http } from "../../instance/axios.instance"
import HeroHeader from '../../components/header/useHeroHeader';
import { useParams, Link } from 'react-router-dom';
import { appendScript } from '../../utils/appendScript'
import { formatDate, loadDocs } from "../../utils/utils"
import parse from 'html-react-parser';


function DirectorateDivisionDetails() {
    let params = useParams();
    const fullName = params.name;
    const position = params.position;
    const division = params.division;
    const divisionID = params.divisionId;
    const image = "/uploads/" + params.avatar;
    const profile = atob(unescape(encodeURIComponent(params.profile)));
    
    useEffect(() => {}, []);

    return (
        <>

            <HeroHeader name={fullName} />
            <div className="attorney-details pt-100 pb-70">
                <div className="container faq-area">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="attor-details-item">
                                <img src={loadDocs(image)} alt="Image" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="attor-details-item">
                                <div className="attor-details-right">
                                    <div className="attor-details-name">
                                        <h2>{fullName}</h2>
                                        <table className="table mt-3 table-striped">
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Position</td>
                                                    <td>{position}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Division</td>
                                                    <td>{division}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <h4>Profile</h4>
                                                        {
                                                            parse(profile || "")
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-12">
                                        <Link to={`/division-details/${divisionID}`}>
                                            <strong> Go Back</strong>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DirectorateDivisionDetails;