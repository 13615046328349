import React, { useState, useEffect } from 'react';
import { $http } from "../../instance/axios.instance"
import HeroHeader from '../../components/header/useHeroHeader';
import TwoSided from '../../components/others/two-sided';
import { appendScript } from '../../utils/appendScript'

const SeniorityList = () => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        appendScript("assets/js/custom.js");
        async function fetchData() {
            try {
                setLoading(true)
                const res = await $http.get("seniority-lists")
                setList(res.data.data)
                setLoading(false)
            }
            catch (e) {
                console.log(e)
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <HeroHeader name={"Seniority List of Hon. Justices"} />
            <TwoSided>
                <div className="law-card-area">
                    <div className="container">
                        <div className="card-contant">
                            <div className="row no-gutters">
                            <h2>Seniority List of Hon. Justices</h2>
                                <table className="table mt-3 table-striped">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading ? list.map((item, index) =>
                                            <tr>
                                                <td scope="row">{index + 1}</td>
                                                <td>{item.name}</td>
                                            </tr>
                                        ) :
                                            <h2 className='loading_center'>...loading</h2>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </TwoSided>
        </>
    );
}

export default SeniorityList