const axios = require('axios');
const CancelToken = axios.CancelToken;
const http = axios.http;

export default
{
    // `method` is the request method to be used when making the request
    method: 'get', // default
  
    // `baseURL` will be prepended to `url` unless `url` is absolute.
    // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
    // to methods of that instance.
    //baseURL: 'http://localhost:1337/api/',
    baseURL: 'https://coa-admin.courtofappeal.gov.ng/api/',
    // `timeout` specifies the number of milliseconds before the request times out.
    // If the request takes longer than `timeout`, the request will be aborted.
    timeout: 0, // default is `0` (no timeout)

    responseType: 'json', // default
  
    // `responseEncoding` indicates encoding to use for decoding responses (Node.js only)
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: 'utf8', // default
  
    // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
    xsrfCookieName: 'XSRF-TOKEN', // default
  
    // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
    xsrfHeaderName: 'X-XSRF-TOKEN', // default
  
    // `onUploadProgress` allows handling of progress events for uploads
    // browser only
    onUploadProgress: function (progressEvent) {
      // Do whatever you want with the native progress event
    },
  
    // `onDownloadProgress` allows handling of progress events for downloads
    // browser only
    onDownloadProgress: function (progressEvent) {
      // Do whatever you want with the native progress event
    },
  
    // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
    maxContentLength: 2000,
  
    // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
    maxBodyLength: 2000,
    // proxy: {
    //   protocol: 'http',
    //   host: 'http://coaadmin.mbrcomputers.com',
    //   port: 1337
    // }
  };