import React from 'react';
import HeroHeader from '../../components/header/useHeroHeader';
class Help extends React.Component {
	render() {
		return (
			<>
				<HeroHeader name={"Help"} />
			</>
		);
	}
}

export default Help