export const formatDate = (date) =>{
  if(date === undefined || date === null || date === "")
  {
    return date;
  }

  let initial = date.split("/");
  if(initial.length > 1)
  {
    return [initial[1], initial[0], initial[2]].join('/');
  }
  else{
    initial = date.split("-");
    return [initial[2], initial[1], initial[0]].join('/');
  }
}

export const loadDocs = (link) =>{
  if(link === "" || undefined || null)
  {
    return "assets/img/placeholder.png";
  }
  return "https://coa-admin.courtofappeal.gov.ng" + link;
}