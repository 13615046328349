import React, { useState, useEffect } from 'react';
import HeroHeader from '../components/header/useHeroHeader';
import { appendScript } from '../utils/appendScript'
import {$http} from "../instance/axios.instance"

const FAQ = () => {
    const [faqs, setFaqs] = useState([]);
    const [loading, SetLoading] = useState(true);
    const reload = false;
    useEffect(()=>{
        appendScript("assets/js/custom.js");
        async function fetchData(){
            try{
                const response =  await $http.get("faqs");
                setFaqs(response.data.data);
                SetLoading(false)
            }
            catch(e){
                console.log(e)
                SetLoading(false)
            }
        };
        fetchData();
    },[reload]);

    return (
        <>
            <HeroHeader name={"FAQ"} />
            <div className="faq-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-12">
                            <div className="faq-accordion">
                                <ul className="accordion">
                                    {!loading ? faqs.map((item, index) =>
                                        <li className="accordion-item">
                                            <a className={`accordion-title ${index == 0 ? 'active' : ''}`} href="javascript:void(0)">
                                                <i className="las la-plus"></i>
                                                {item.question}
                                            </a>

                                            <p className="accordion-content show">
                                                {item.answer}
                                            </p>
                                        </li>): <h2 className='loading_center'>...loading</h2>
                                    }
                                </ul>
                                <div className='loading_center'>No FAQ yet. Please check back soon!</div>
                            </div>
                        </div>

                        {/* <div className="col-lg-5 col-md-12">
                            <div className="faq-image">
                                <img src="https://www.partnersnigeria.org/wp-content/uploads/2018/11/Judiciary_logo.jpg" alt="image" />
                            </div>
                        </div> */}
                    </div>

                    {/*<div className="contact-form">
                        <form id="contactForm">
                            <div className="section-title">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="name" className="form-control" id="name" required data-error="Please enter your name" placeholder="Your Name" />
                                        <div className="help-block with-errors"></div>
                                        <i className="las la-user"></i>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="email" name="email" className="form-control" id="email" required data-error="Please enter your email" placeholder="Email Address" />
                                        <div className="help-block with-errors"></div>
                                        <i className="las la-envelope"></i>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="email" className="form-control" id="Phone" required data-error="Please enter your phone" placeholder="Your Phone" />
                                        <div className="help-block with-errors"></div>
                                        <i className="las la-phone"></i>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="email" className="form-control" id="subject" required data-error="Please enter your subject" placeholder="Your subject" />
                                        <div className="help-block with-errors"></div>
                                        <i className="las la-id-card"></i>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <textarea name="message" id="message" className="form-control" cols="30" rows="6" required data-error="Please enter your message" placeholder="Write your message..."></textarea>
                                        <div className="help-block with-errors"></div>
                                        <i className="las la-sms"></i>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <button type="button" className="default-btn-one">Submit</button>
                                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </form>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default FAQ