import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { $http } from "../../instance/axios.instance"
import { appendScript } from '../../utils/appendScript'
import ContactFormSection from '../../components/Contact/contactFormSection';
import { loadDocs } from '../../utils/utils';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./Index.css"
import useAxiosGet from '../../components/useAxiosGet';
import parse from 'html-react-parser';

const HomePage = () => {
    const { data: siteCounter } = useAxiosGet("site-counter");    

    const [email, setEmail] = useState("")
    const [news, setNews] = useState([]);
    const [justices, setJustices] = useState([]);
    const [teams, setTeam] = useState([]);
    const [sliders, setSlider] = useState([]);
    //const siteCounter = {};
    const [loading, setLoading] = useState();
    const [patnerOption] = useState({
        loop: true,
        dots: true,
        margin: 30,
        nav: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2
            },
            576: {
                items: 3
            },
            768: {
                items: 3
            },
            1200: {
                items: 3
            }
        }
    })
    const [sliderOption] = useState({
        items: 1,
        loop: true,
        margin: 0,
        nav: true,
        autoHeight: true,
        autoplay: true,
        autoplayHoverPause: true,
        dots: true,
        navText: [
            "<i class='las la-angle-left'></i>",
            "<i class='las la-angle-right'></i>"
        ]
    })


    useEffect(() => {
        appendScript("assets/js/custom.js");
    }, [])

    const reload = false;
    useEffect(() => {
        async function fetchData() {
            try {
                const [justices_res, posts_res, team_res, slider_res] = await Promise.all([
                    $http.get("justices/minify?withImage=&ps=20&presid=true"),
                    $http.get("posts?minify=true&max=3"),
                    $http.get("management-staffs"),
                    $http.get("sliders"),
                ]);
                if (justices_res) {
                    setJustices(justices_res.data.data.slice(0, 40));
                }
                if (posts_res) {
                    setNews(posts_res.data.data);
                }
                if (team_res) {
                    setTeam(team_res.data.data);

                }

                if (slider_res) {

                    const slides = slider_res.data.data.map((item, index) => {
                        item.item_bg = `url(${loadDocs(item.url)})`;
                        return item;
                    })

                    setSlider(slides);
                }
                
            }
            catch (e) {
                console.log(e)
            }
        };
        fetchData();
    }, []);

    async function handleSubmit(event) {
        try {
            if (loading) {
                return;
            }

            event.preventDefault();
            setLoading(true)
            const res = await $http.post("newsletters", {
                "data": {
                    "Email": email
                }
            });
            if (res) {
                setEmail("")
                setLoading(false)
                alert("Thank you for subscribing to our newsletter")
            }
        }
        catch (e) {
            console.log(e)
            setLoading(false)
        }
    };

    return (
        <>
            {sliders.length > 0 &&
                <OwlCarousel className='owl-theme' {...sliderOption}>
                    {
                        sliders.map((item, index) => (
                            <div className='item' key={index} style={{ backgroundImage: item.item_bg, height: "700px" }}>

                            </div>
                        ))
                    }
                </OwlCarousel>
            }
            {/* End Hero Slider Area */}

            {/* About Area */}
            <div className="about-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5" style={{ textAlign: "center", verticalAlign: "middle" }}>
                            <div className="about-image">
                                <img src="assets/img/president.jpeg" alt="Image" />
                            </div>
                            <h6>Hon. Justice Monica Bolna'an Dongban-Mensem</h6>
                            <p><strong>President, Court of Appeal</strong></p>
                        </div>

                        <div className="col-lg-7">
                            <div className="about-text">
                                <div className="section-title">
                                    <span>About Us</span>

                                    <h2>A BRIEF HISTORY OF THE COURT OF APPEAL OF NIGERIA</h2>

                                    <p style={{ textAlign: "justify" }}>
                                        The Court of Appeal is the Penultimate Court in the hierarchy of Courts in Nigeria. Prior to its establishment, there had been a system of direct Appeals from the High Courts to the Federal Supreme Court, which had become the Nation’s Apex Court in 1963 following the abolition of appeals to the Judicial Committee of the Privy Council.

                                        In a bid to ensure efficient justice delivery, the need arose for the establishment of an intermediate Court between the High Courts and the Supreme Court of Nigeria (the Apex Court), this led the Government of the Western State to establish a Court known as the Western Nigeria Court of Appeal in 1967. The Court was recognized by the Constitution (Miscellaneous) (No. 2) Decree, 1967, which by Section 3, abolished the old system of direct appeals from the High Courts to the Supreme Court. This Court is considered the forerunner of the Court of Appeal in many respects.


                                        <p>
                                            <Link to="/history" className="default-btn-one">
                                                Read More
                                            </Link>
                                        </p>

                                    </p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area */}

            {/* Service Area */}
            <div className="service-area ptb-50">
                <div className="container">
                    <div className="section-title">
                        <h2>MISSION/VISION</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <div className="service-card mission">
                                <div className="service-text">
                                    <i className="las la-users"></i>
                                    <h3><a href="#"><span>Mission</span></a></h3>
                                    <p className="align-text-item">
                                        To promote equal access to Justice through a fair, transparent and expeditious adjudicatory process by the deployment of technology and Alternative Dispute Resolution mechanisms, thereby engendering public trust and confidence.

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="service-card mission">
                                <div className="service-text">
                                    <i className="las la-user"></i>
                                    <h3><a href="#"><span>Vision</span></a></h3>
                                    <p className="align-text-item"> To be an independent, effective and efficient Appellate Court in the Justice delivery architecture.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area */}

            {/* Attorney Area */}

            <div className="partner-area ptb-50">
                <div className="container">
                    <div className="section-title">
                        <h2>PRESIDING JUSTICES OF THE COURT OF APPEAL OF NIGERIA</h2>
                    </div>

                    {justices.length > 0 &&
                        <OwlCarousel className='partner-slider owl-theme' {...patnerOption}>
                            {
                                justices.map((item, index) => (
                                    <div key={index} className="partner-slider-item">
                                        <Link to={`/justice-details/${item.id}`}>
                                            <img src={loadDocs(item.image)} alt="Image" />
                                            <p>{item.name}</p>
                                        </Link>
                                    </div>
                                ))
                            }
                        </OwlCarousel>
                    }

                </div>
            </div>

            {/* Right Way Area */}
            <div className="right-way-area service-area ptb-100">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 right-way">

                            <div className="fun-facts-shape top">
                                <img src="assets/img/shape.png" className="shape1" alt="Shape" />
                                <img src="assets/img/shape.png" className="shape2" alt="Shape" />
                            </div>

                            <div className="right-way-text" >
                                <div className="section-title">
                                    <h3>The Court of Appeal is the Penultimate Court in the hierarchy of Courts in Nigeria.</h3>
                                </div>

                                <div className="text-sign">
                                    <h3>Hon. Justice Monica Bolna'an Dongban-Mensem</h3>
                                    <p>President Court of Appeal</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="video-contant">
                                <div className="d-table">
                                    <div className="d-table-cell">
                                        <div className="video-box">
                                            <a href="https://www.youtube.com/watch?v=zn95SIItUvE" className="video-btn popup-youtube">
                                                <i className="las la-play"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Right Way Area */}

            <div className="partner-area ptb-50">
                <div className="container">
                    <div className="section-title">
                        <h2>MANAGEMENT</h2>
                    </div>


                    {teams.length > 0 &&
                        <OwlCarousel className='partner-slider owl-theme' {...patnerOption}>
                            {
                                teams.map((item, index) => (
                                    <div key={index} className="partner-slider-item">
                                        <Link to={`/management-staff/${item.id}`}>
                                            <img src={loadDocs(item.image)} alt="Image" />
                                            <p>{item.name}</p>
                                        </Link>
                                    </div>
                                ))
                            }
                        </OwlCarousel>
                    }
                </div>
            </div>

            {/* Blog Area */}
            <div className="blog-area service-area pt-100 pb-70">
                <div className="container">
                    <div className="section-title">
                        <span>Latest News</span>
                        <h2>NEWS AND EVENTS</h2>
                    </div>

                    <div className="row">
                        {news.length > 0
                            ? news.map((item, index) =>
                                <div key={index} className="col-lg-4 col-sm-6">
                                    <div className="blog-card">
                                        <Link to={`/news-details/${item.id}`}>
                                            <img src={loadDocs(item.image.url)} alt="Image" />
                                        </Link>
                                        <div className="blog-card-text">
                                            <h3><Link to={`/news-details/${item.id}`}>{item.title}</Link></h3>
                                            <ul>
                                                <li>
                                                    <i className="las la-calendar"></i>
                                                    {item.date}
                                                </li>
                                                <li>
                                                    <i className="las la-user-alt"></i>
                                                    {item.author}
                                                </li>
                                            </ul>

                                            <p>
                                                {parse(item.abstract || "")}
                                            </p>

                                            <Link to={`/news-details/${item.id}`} className="read-more">
                                                Read More <i className="las la-angle-double-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                    </div>
                </div>
            </div>
            <div className="contact-area ptb-100 case-study-area">
                <div className="container">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h2>Subscribe to our newsletter</h2>
                        </div>
                        <div className="contact-form">
                            <form id="contactForm" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <input type="text" name="email" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" id="email" required data-error="Please enter your name" placeholder="Email address" />
                                            <div className="help-block with-errors"></div>
                                            <i className="las la-sms"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="submit" className="default-btn-one mt-1">{
                                            loading ? "subscribing" : "Subscibe"
                                        }</button>
                                        <div id="msgSubmit" className="h3 text-center hidden"></div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="fun-facts-area fun-facts-2 pt-100 pb-70">
                <div className="container">
                    <div className="fun-facts-shape top">
                        <img src="assets/img/shape.png" className="shape1" alt="Shape" />
                        <img src="assets/img/shape.png" className="shape2" alt="Shape" />
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="signle-fun-fact">
                                <i className="las la-balance-scale"></i>
                                <h3><span className="odometer" data-count={ siteCounter && siteCounter.dailyCounter}>{ siteCounter && siteCounter.dailyCounter}</span>
                                    </h3>
                                <p>Today's Visitors</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="signle-fun-fact">
                                <i className="las la-balance-scale"></i>
                                <h3><span className="odometer" data-count={ siteCounter && siteCounter.monthlyCounter}>{ siteCounter && siteCounter.monthlyCounter}</span>
                                    </h3>
                                <p>This Month Visitors</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="signle-fun-fact">
                                <i className="las la-balance-scale"></i>
                                <h3><span className="odometer" data-count={ siteCounter && siteCounter.yearlyCounter}>{ siteCounter && siteCounter.yearlyCounter}</span>
                                    </h3>
                                <p>This Year Visitors</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="signle-fun-fact">
                                <i className="las la-balance-scale"></i>
                                <h3><span className="odometer" data-count={ siteCounter && siteCounter.totalCounter}>{ siteCounter && siteCounter.totalCounter}</span>
                                </h3>
                                <p>Total Visitors</p>
                            </div>
                        </div>
                    </div>
                    <div className="fun-facts-shape bottom">
                        <img src="assets/img/shape.png" className="shape1" alt="Shape" />
                        <img src="assets/img/shape.png" className="shape2" alt="Shape" />
                    </div>
                </div>
            </div>


            <ContactFormSection showTermsAgreement={false} />
        </>
    );


}

export default HomePage;