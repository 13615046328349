import { $http } from "../instance/axios.instance"
import React, { useEffect, useState } from 'react';

const useAxiosGet = (url) => {
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
          const response = await $http.get(url);
          setData(response.data.data);        
      } catch (error) {
        setError(error.message);
      } finally {
        setLoaded(true);
      }
    })();
  }, []);

  return { data, error, loaded };
};

export default useAxiosGet;