import React, { useEffect, useState } from 'react';
import { $http } from "../../instance/axios.instance"
import HeroHeader from '../../components/header/useHeroHeader';
import { appendScript } from '../../utils/appendScript'
import { loadDocs } from '../../utils/utils';
import parse from 'html-react-parser';

const PioneerPresident = () => {

    const [data, setData] = useState({content:""});
    const reload = false;
    useEffect(() => {
        appendScript("assets/js/custom.js");
        async function fetchData() {
            try {
                const response = await $http.get("pioneer-president");
                setData(response.data.data);
            }
            catch (e) {
                console.log(e)
            }
        };
        fetchData();
    }, [reload]);

    return (
        <>
            <HeroHeader name={"Pioneer President"} />
            
            <div className="about-area ptb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about-image">
                                <img src={loadDocs(data.image)} alt="Image" />
                            </div>
                            <h6 style={{ textAlign: "center" }}>{data.name}</h6>
                            <p style={{ textAlign: "center" }}><strong>{data.title}</strong></p>
                        </div>

                        <div className="col-lg-7 align-text-item">
                                        {data &&
                                            parse(data.content)
                                        }
                                   
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PioneerPresident