import React from 'react';
import { Menu } from './Menu';
import { Link } from "react-router-dom";
import TopHeader from "./firstTopHeader";
function header() {
    return (
        <>
		 <header className="header-area">
            <TopHeader/>
            <div className="navbar-area">
                <div className="atorn-responsive-nav">
                    <div className="container">
                        <div className="atorn-responsive-menu">
                            <div className="logo">
                                <Link to="/">
                                    <img src={`assets/img/logo.png`} className="logo1" alt="logo"/>
                                    <img src={`assets/img/logo-white.png`} className="logo2" alt="logo"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Menu />
            </div>
        </header>
		
		<div className="search-overlay">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="search-overlay-layer"></div>
                    <div className="search-overlay-layer"></div>
                    <div className="search-overlay-layer"></div>
                    
                    <div className="search-overlay-close">
                        <span className="search-overlay-close-line"></span>
                        <span className="search-overlay-close-line"></span>
                    </div>

                    <div className="search-overlay-form">
                        <form>
                            <input type="text" className="input-search" placeholder="Search here..."/>
                            <button type="submit"><i className='las la-search'></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default header