import React, { lazy, Suspense, useLayoutEffect, useState, useEffect, createContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { $http } from "../instance/axios.instance"
import HomePage from '../pages/homepage/Index';
import Help from '../pages/media/help';
import FAQ from '../pages/faq';
import Terms from '../pages/terms';
import Privacy from '../pages/privacy';
import History from '../pages/about/history';
import NewsDetails from '../pages/media/newsDetails';
import Loading from '../components/loading';
import Layout from "../layout/layout"
import SeniorityList from '../pages/about/seniority-list';
import JudicialArm from '../pages/about/judicial-arm';
import Loader from '../components/loader';
import AdministrativeStructure from '../pages/about/administrative-structure';
import ManagementStaffDetails from '../pages/team/management-details';
import PioneerPresident from '../pages/about/PioneerPresident';
import DivisionDetails from '../pages/divisions/division-details';
import DirectorateDetails from '../pages/team/directorate-details'; 
import DirectorateDivisionDetails from '../pages/team/directorate-division-details';
import DeputyRegistrarDetails from '../pages/team/dp-staff-details';
import Login from '../pages/auth/login';
import { ProtectRoutes } from "../hooks/protectRoutes/index"
import AddCauselist from '../pages/admin/add-causelist';
import AddJudement from '../pages/admin/add-judgement';
import ViewCauseList from '../pages/admin/causeList';
import ViewJudgement from '../pages/admin/judgement';


const Error404 = lazy(() => import("../pages/error/404"));
const VisionMission = lazy(() => import('../pages/about/vision-mission'));

// About
const About = lazy(() => import('../pages/about/about'));
const AppealJustices = lazy(() => import('../pages/about/justices-court-of-appeal'));
const FormerPesidents = lazy(() => import('../pages/about/former-presidents'));
const CurrentJustices = lazy(() => import('../pages/about/current-justice'));
const Jurisdiction = lazy(() => import('../pages/about/juristiction'));
//const SeniorityList= lazy(() => import('../pages/about/seniority-list'));

// Litigation
const Judgement = lazy(() => import('../pages/litigation/judgement'));
const CauseList = lazy(() => import('../pages/litigation/causeList'));

// Others
const Contact = lazy(() => import('../pages/contact'));
const Page = lazy(() => import('../pages/page'));
const Divisions = lazy(() => import('../pages/divisions/divisions'));
const Judges = lazy(() => import('../pages/past-justices'));
const JudgeDetails = lazy(() => import('../pages/about/judge-details'));

// Media
const News = lazy(() => import('../pages/media/news'));
const PictureGallery = lazy(() => import('../pages/media/pictureGallery'));
const PictureCategory = lazy(() => import('../pages/media/picture-category'));
const Videos = lazy(() => import('../pages/media/videos'));

// Team Members
const PastChiefRegistrar = lazy(() => import('../pages/team/past-chief-registrar-court-of-appeal'));
const ManagementStaff = lazy(() => import('../pages/team/management-staff'));
const Departments = lazy(() => import('../pages/team/departments'));
const ChiefRegistrar = lazy(() => import('../pages/team/chief-registrar'));
const Structure = lazy(() => import('../pages/team/structure'));
const Team = lazy(() => import('../pages/team'));
const DeputyRegistrar = lazy(() => import('../pages/team/deputy-registrar'));
const DirectorateCadreHQ = lazy(() => import('../pages/team/directorateCadreHQ'));

const CourtDocuments = lazy(() => import('../pages/documents/index'));

const DirectorateCadre = lazy(() => import('../pages/divisions/directorate-cadre'));
const JusticePosting = lazy(() => import('../pages/divisions/justice-posting'));

const Pages = () => {
    const [news, setNews] = useState([]);
    const NewsContext = createContext()
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    const reload = false;
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await $http.get("posts?minify=true");
                setNews(response.data.data);
            }
            catch (e) {
                console.log(e)
            }
        };
        fetchData();
    }, [reload]);

    return (
        <Suspense fallback={<Loading />}>
            <Routes>

                <Route path="/" element={<Layout />}>

                    {/* Home */}
                    <Route index element={<NewsContext.Provider value={news}>
                        <HomePage />
                    </NewsContext.Provider>} />
                    <Route path='vision-mission' element={<VisionMission />} />
                    <Route path='/history' element={<History />} />

                    <Route path='/login' element={<Login />} />

                    <Route element={<ProtectRoutes />}>
                        <Route path='/admin/add-causelist' element={<AddCauselist />} />
                        <Route path='/admin/add-causelist/:id' element={<AddCauselist />} />
                        <Route path='/admin/add-judgment' element={<AddJudement />} />
                        <Route path='/admin/add-judgment/:id' element={<AddJudement />} />
                        <Route path='/admin/view-judgment' element={<ViewJudgement />} />
                        <Route path='/admin/view-causelist' element={<ViewCauseList />} />
                        <Route path='/admin' element={<AddJudement />} />
                    </Route>

                    {/* About Routes */}
                    <Route path="about" element={<About />} />
                    <Route path='former-presidents' element={
                        <NewsContext.Provider value={news}>
                            <FormerPesidents />
                        </NewsContext.Provider>
                    } />
                    <Route path='judicial-arm' element={<JudicialArm />} />
                    <Route path='former-justices' element={<AppealJustices />} />
                    <Route path='current-justices' element={<CurrentJustices />} />
                    <Route path='jurisdiction' element={<Jurisdiction />} />
                    <Route path='pioneer-president' element={<PioneerPresident />} />
                    <Route path='past-judges-of-nigeria' element={<Judges />} />
                    <Route path='justice-details/:id' element={<JudgeDetails />} />
                    <Route path='seniority-list' element={<SeniorityList />} />
                    <Route path='administrative-structure' element={<AdministrativeStructure />} />


                    <Route path="/page" element={<Page />} />

                    {/* Media */}
                    <Route path="/news" element={<News />} />
                    <Route exact path={"/news-details/:id"} element={<NewsDetails />}></Route>
                    <Route path="/videos" element={<Videos />} />
                    <Route path="/gallery" element={<PictureGallery />} />
                    <Route path="/gallery/:id" element={<PictureGallery />} />
                    <Route path="/picture-category" element={<PictureCategory />} />

                    {/* Court Divisions */}
                    <Route path='/divisions' element={<Divisions />} />
                    <Route path='/division-details/:id' element={<DivisionDetails />} />
                    <Route path='/directorate-cadre' element={<DirectorateCadre />} />
                    <Route path='/directorate/:id' element={<DirectorateDetails />} />
                    <Route path='/directorate-division-details/:name/:position/:division/:divisionId/:avatar/:profile' element={<DirectorateDivisionDetails />} />
                    <Route path='/justice-posting' element={<JusticePosting />} />
                    <Route path='/documents' element={<CourtDocuments />} />

                    {/* Litigations */}
                    <Route path="/judgment" element={<Judgement />} />
                    <Route path="/cause-list" element={<CauseList />} />


                    {/* Management Team Routes */}
                    <Route path='/structure' element={<Structure />} />
                    <Route path='/past-chief-registrar-court-of-appeal' element={<PastChiefRegistrar />} />
                    <Route path='/chief-registrar' element={<ChiefRegistrar />} />
                    <Route path='/management-staff' element={<ManagementStaff />} />
                    <Route path='/departments-units' element={<Departments />} />
                    <Route path='/deputy-registrar' element={<DeputyRegistrar />} />
                    <Route path='/dp-staff/:id' element={<DeputyRegistrarDetails />} />
                    <Route path='management-staff/:id' element={<ManagementStaffDetails />} />
                    <Route path='/directorate-hq' element={<DirectorateCadreHQ />} />

                    {/* Others */}
                    <Route path="contact" element={<Contact />} />

                    <Route path='/error' element={<Error404 />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="*" element={({ location }) => (location.state && location.state.is404 ? <Error404 /> : <Navigate to="/" />)} />
                </Route>
            </Routes>
        </Suspense>
    );
}

export default Pages;