import React, { useEffect } from "react";
import Pages from "./route/Index";
import { useSearchParams, useNavigate } from 'react-router-dom';

const App = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate();
    let page = searchParams.get("link");
    useEffect(() => {
        if (page) {
            navigate(page)
        }
    })
    return (
        <Pages />
    );
};
export default App;
