
export const reducer = (state, action) => {
    switch (action.type) {
      case "SET_USER":
        return {
          ...state,
          user: action.user
        }
        case "GET_USER":
          return {
              ...state,
              user: state.user
          }
      default:
        return state
    }
  }
  
  export const initialState = {
    user: {}
  }