import React, { useState, useEffect, createContext, useContext } from 'react';
import { Link } from "react-router-dom";
import { $http } from "../../instance/axios.instance"
import { formatDate, loadDocs } from "../../utils/utils"


function RightSideBar() {
    const [list, setList] = useState([]);
    const NewsContext = createContext();
    const news = useContext(NewsContext);
    console.log(news)
    const reload = false;
    useEffect(() => {
        async function fetchData() {
            try {
                if (!news) {
                    const response = await $http.get("posts?minify=true&max=5");
                    console.log('====================================');
                    console.log(response.data.data);
                    console.log('====================================');
                    setList(response.data.data);
                }
                else {
                    setList(news);
                }
            }
            catch (e) {
                console.log(e)
            }
        };
        fetchData();
    }, [reload]);



    return (
        <div className="side-bar">
            <div className="side-bar-box recent-post">
                <h3 className="title">Recent News</h3>
                {list.map((item) =>
                    <div className="single-recent-post">
                        <div className="recent-post-img">
                            <Link to={`/news-details/${item.id}`}>
                                <img src={loadDocs(item.image.url)} alt="Image" />
                            </Link>
                        </div>
                        <div className="recent-post-content">
                            <h3><Link to={`/news-details/${item.id}`}>{item.title}</Link></h3>
                            <ul>
                                {/* <li><a href="#">Posted on: {item.author}</a></li> */}
                                <li>
                                    <a href="#"><i className="las la-calendar"></i>
                                        {formatDate(item.date)}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>


        </div>
    );
}
export default RightSideBar
