import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function HeroHeader({name}){

    useEffect(() => {
        document.title = `Nigeria Court of Appeal: ${name}`;
    });

  return(
    <>
       <div className="page-banner-small bg-1">
          
      </div>
    </>
  );
};

export default HeroHeader;