import React, {useEffect, useState} from 'react';
import { $http } from "../../instance/axios.instance"
import UseHeroHeader from '../../components/header/useHeroHeader';
import TwoSided from '../../components/others/two-sided';
import { appendScript } from '../../utils/appendScript'
import parse from 'html-react-parser';

const History = () => {
    const [history, setData] = useState({});
    const reload = false;
    useEffect(() => {
        appendScript("assets/js/custom.js");
        async function fetchData() {
            try {
                const response = await $http.get("brief-history");
                setData(response.data.data);
            }
            catch (e) {
                console.log(e)
            }
        };
        fetchData();
    }, [reload]);
        return (
            <>
                <UseHeroHeader name={"Brief History of the Court of Appeal"} />
                <TwoSided>
                    {/* About Area */}
                    <div className="law-card-area">
					<div className="container">
						<div className="card-contant">
							<div className="row no-gutters">
								<div className="align-text-item">
                                    {parse(history.content || "")}
                                                
                                        </div>


                                    </div>
                                </div>
                            
                        </div>
                    </div>
                    {/* End About Area */}

                </TwoSided>
            </>
        );
}

export default History