import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useAuth } from '../../context/user/user.context'
import HeroHeader from '../../components/header/useHeroHeader';

const Login = () => {
    const { login, redirectNow } = useAuth();

    const [form, setForm] = useState({
        email: "",
        password: "",
        isAnonymous: false
    });

    const [loginText, setLoginText] = useState("Login");
    const [loading, setLoading] = useState(false)
    const onFormInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const onSubmit = async (event) => {
        if (loading) {
            return;
        }

        event.preventDefault();
        setLoading(true)
        setLoginText("...please wait")

        var res = await login(form)
        setLoginText("Login")
        if(res){
            redirectNow()
        }
    }

    return (
        <>
            <HeroHeader name={"Login"} />
            <section className="login-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="login-content">
                                <h2>Login</h2>

                                <form method='post' className="login-form">
                                    <div className="form-group">
                                        <input type="email"
                                            value={form.email}
                                            name="email"
                                            onChange={onFormInputChange}
                                            className="form-control" placeholder="Username or email address" />
                                    </div>

                                    <div className="form-group pt-3">
                                        <input type="password"
                                            value={form.password}
                                            name="password"
                                            onChange={onFormInputChange}
                                            className="form-control" placeholder="Password" />
                                    </div>

                                    <button onClick={onSubmit} type="submit" className="mt-3 default-btn">{loginText}</button>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">

                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}
export default Login;