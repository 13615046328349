import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { appendScript } from '../../utils/appendScript'
import { $http } from "../../instance/axios.instance"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../context/user/user.context';
import HeroHeader from '../../components/header/hero-header-small';

const AddCauselist = () => {
    const [busy, setBusy] = useState(false);
    const [btText, setBtText] = useState("Submit");
    const { user } = useAuth();
    let params = useParams();
    const id = params.id;

    let initForm = {
        AppealNo: "",
        Parties: "",
        Nature: "",
        Date: "",
        BusinessOfDay: "",
    };

    const [form, setForm] = useState(initForm);

    useEffect(() => {
        appendScript("assets/js/custom.js");

        if (id) {
            $http.get(`cause-lists/${id}`, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem("token")}`
                }
            }).then((e) => {
                setForm(e.data.data.attributes);
                setBtText("Update")
            })
        }
    }, []);

    const onFormInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const onSubmit = async (event) => {
        if (busy) {
            return;
        }

        event.preventDefault();
        setBusy(true)
        setBtText("...please wait")
        form.Division = user.division
        if (!id) {
            $http.post("cause-lists", { data: form }, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem("token")}`
                }
            }).then((e) => {
                setBusy(false);
                setForm(initForm);
                setBtText("Submit")
                toast.success('Operation successful', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
                .error((e) => {
                    setBusy(false);
                    setBtText("Submit")
                    toast.error('There was an error completing the request, please try again later', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
        else {
            $http.put(`cause-lists/${id}`, { data: form }, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem("token")}`
                }
            }).then((e) => {
                setBusy(false);
                setForm(initForm);
                setBtText("Submit")
                toast.success('Update successful', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
                .error((e) => {
                    setBusy(false);
                    setBtText("Submit")
                    toast.error('There was an error completing the request, please try again later', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
    }
    return (
        <>
            <HeroHeader />
            <div className="pt-50 pb-100">
                <div className="container">
                    <div className="card-contant">
                        <div className="row no-gutters">
                            <h2>Add Causelist</h2>
                            <form>
                                <div className="row pt-3 ">
                                    <div className="col-md-8">
                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Appeal No.</label>
                                            <div className="col-sm-10">
                                                <input type="text" name='AppealNo' onChange={onFormInputChange} value={form.AppealNo} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Parties</label>
                                            <div className="col-sm-10">
                                                <input type="text" name='Parties' onChange={onFormInputChange} value={form.Parties} className="form-control" />
                                            </div>

                                        </div>
                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Business of the Day</label>
                                            <div className="col-sm-10">
                                                <input type="text" name='BusinessOfDay' onChange={onFormInputChange} value={form.BusinessOfDay} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Nature</label>
                                            <div className="col-sm-10">
                                                <input type="text" name='Nature' onChange={onFormInputChange} value={form.Nature} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Date</label>
                                            <div className="col-sm-10">
                                                <input type="date" name='Date' onChange={onFormInputChange} value={form.Date} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <div className="col-sm-10">
                                                <button type="submit" onClick={onSubmit} className="default-btn-one mt-1">{btText}</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddCauselist