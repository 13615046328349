import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeroHeader from '../../components/header/hero-header-small';
import { appendScript } from '../../utils/appendScript'
import { $http } from "../../instance/axios.instance"
import { useAuth } from '../../context/user/user.context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddJudement = () => {
    const [busy, setBusy] = useState(false);
    const [btText, setBtText] = useState("Submit");
    const { user } = useAuth();
    let params = useParams();
    const id = params.id;

    useEffect(() => {
        appendScript("assets/js/custom.js");
    }, []);

    const initForm = {
        FileNo: "",
        Parties: "",
        SubjectMatter: "",
        Date: "",
        Justice: "",
        Url: "",
        SubstantialIssue: "",
        Division: user.division
    };

    const [form, setForm] = useState(initForm);

    useEffect(() => {
        if (id) {
            $http.get(`judgements/${id}`, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem("token")}`
                }
            }).then((e) => {
                setForm(e.data.data.attributes);
                setBtText("Update")
            })
        }
    }, []);

    const onFormInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };
    const onSubmit = async (event) => {
        if (busy) {
            return;
        }

        event.preventDefault();
        setBusy(true)
        setBtText("...please wait")

        if (!id) {
            $http.post("judgements", { data: form }, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem("token")}`
                }
            })
                .then((e) => {
                    setBusy(false);
                    setForm(initForm);
                    setBtText("Submit")
                    toast.success('Operation successful', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
                .error((e) => {
                    setBusy(false);
                    setBtText("Submit")
                    toast.error('There was an error completing the request, please try again later', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
        else {
            $http.put(`judgements/${id}`, { data: form }, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem("token")}`
                }
            })
                .then((e) => {
                    setBusy(false);
                    setForm(initForm);
                    setBtText("Submit")
                    toast.success('Update successful', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
                .error((e) => {
                    setBusy(false);
                    setBtText("Submit")
                    toast.error('There was an error completing the request, please try again later', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
        }
    }

    return (
        <>
            <HeroHeader />
            <div className="pt-50 pb-100">
                <div className="container">
                    <div className="card-contant">
                        <div className="row no-gutters">
                            <h2>Add Judgment</h2>
                            <form>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">File No.</label>
                                            <div className="col-sm-10">
                                                <input name='FileNo' type="text" onChange={onFormInputChange} value={form.FileNo} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Parties</label>
                                            <div className="col-sm-10">
                                                <input name='Parties' type="text" onChange={onFormInputChange} value={form.Parties} className="form-control" />
                                            </div>

                                        </div>
                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Justice</label>
                                            <div className="col-sm-10">
                                                <input name='Justice' type="text" onChange={onFormInputChange} value={form.Justice} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Subject Matter</label>
                                            <div className="col-sm-10">
                                                <input type="text" name='SubjectMatter' onChange={onFormInputChange} value={form.SubjectMatter} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Date</label>
                                            <div className="col-sm-10">
                                                <input type="date" name='Date' onChange={onFormInputChange} value={form.Date} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Substantial Issue</label>
                                            <div className="col-sm-10">
                                                <input type="text" name='SubstantialIssue' onChange={onFormInputChange} value={form.SubstantialIssue} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <label for="inputPassword" className="col-sm-2 col-form-label">Url</label>
                                            <div className="col-sm-10">
                                                <input type="text" name='Url' onChange={onFormInputChange} value={form.Url} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <div className="col-sm-10">
                                                <button type="submit" onClick={onSubmit} className="default-btn-one mt-1">{btText}</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddJudement