import React, { useEffect, useState } from 'react';
import { $http } from "../../instance/axios.instance"
import { useParams, Link } from 'react-router-dom';
import { loadDocs } from '../../utils/utils';
import { appendScript } from '../../utils/appendScript'
import HeroHeader from '../../components/header/divisionHeroHeader';
import Map from '../../components/Map/map';

const DivisionDetails = () => {

    let params = useParams();
    const id = params.id;
    const [loading, setLoading] = useState(false)
    const [division, setDivision] = useState({ name: "", justices: [], presiding: [], cadres: [], deputy_registrars: [] });
    const [showJustices, setShowJustices] = useState(false)
    const [showPJustices, setShowPJustices] = useState(true)
    const [showDirectorate, setShowDirectorate] = useState(false)
    const [showDeputyRegistrar, setShowDeputyRegistrar] = useState(false)
    const [showManagement, setShowManagement] = useState(false)

    useEffect(() => {
        appendScript("assets/js/custom.js");
        async function fetchData() {
            try {
                setLoading(true)
                const response = await $http.get(`divisions/${id}`);
                console.log("===============================");
                console.log(response);
                console.log("===============================");
                let spData = response.data;
                if (spData.long == null || "") {
                    spData.long = "3.398862"
                }
                if (spData.lat == null || "") {
                    spData.lat = "6.447541"
                }

                setDivision(spData);
                setLoading(false)
            }
            catch (e) {
                console.log(e)
            }
        };
        fetchData();
    }, []);

    const toggleTrueCondition = (mainState) => {
        setShowJustices(false)
        setShowPJustices(false)
        setShowDirectorate(false)
        setShowDeputyRegistrar(false)
        setShowManagement(false)
        mainState(true);
    }

    return (
        <>
            <HeroHeader name={division.name} address={division.address} email={division.email} phone={"+234" + division.phone} />
            <div className="law-card-area ptb-100">
                <div className="container">
                    <div className="card-contant">
                        <div className="row no-gutters">
                            {/* {!loading ?
                                <Map zoomLevel={8} location={{ address: division.address, lat: division.lat, long: division.long }} />
                                :
                                <h2 className='loading_center'>...loading</h2>
                            } */}
                        </div>

                        <div className='row'>
                            <div className='col-lg-9 col-md-8 col-sm-12'>
                                {showJustices &&
                                    <div className="row no-gutters">
                                        <h4>HON. JUSTICES</h4>
                                        <hr />
                                        {division.justices.length > 0 ? division.justices.map((item, index) =>
                                            item.presiding === false && <div key={index} className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                                                <div className="attorney-card">
                                                    <Link to={`/justice-details/${item.id}`}>
                                                        <img className='mx-auto img2' src={loadDocs(item.image)} alt="Image" />
                                                    </Link>
                                                    <div className="attorney-card-text">
                                                        <h4 style={{ fontSize: "14px" }}><Link to={`/justice-details/${item.id}`}>{item.name}</Link></h4>

                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                }
                                {showPJustices &&
                                    <div className="row no-gutters">
                                        <h4>PRESIDING JUSTICE</h4>
                                        <hr />
                                        {division.presiding.length > 0 ? division.presiding.map((item, index) =>
                                            <div key={index} className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                                                <div className="attorney-card">
                                                    <Link to={`/justice-details/${item.id}`}>
                                                        <img className='mx-auto img2' src={loadDocs(item.image)} alt="Image" />
                                                    </Link>
                                                    <div className="attorney-card-text">
                                                        <h4 style={{ fontSize: "14px" }}><Link to={`/justice-details/${item.id}`}>{item.name}</Link></h4>

                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                }

                                {showDirectorate &&
                                    <div className='row no-gutters'>
                                        <h4>DIRECTORATE CADRE</h4>
                                        <hr />
                                        {division.cadres.length > 0 ? division.cadres.map((item, index) =>
                                            <div key={index} className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                                                <div className="attorney-card">
                                                    <Link to={`/directorate-division-details/${item.name}/${item.position}/${item.division}/${item.division_id}/${item.image.replace('/uploads/', '')}/${ btoa(unescape(encodeURIComponent(item.profile))) }`}>
                                                        <img className='mx-auto img2' src={loadDocs(item.image)} alt="Image" />
                                                    </Link>
                                                    <div className="attorney-card-text">
                                                        <Link to={`/directorate-division-details/${item.name}/${item.position}/${item.division}/${item.division_id}/${item.image.replace('/uploads/', '')}/${ btoa(unescape(encodeURIComponent(item.profile))) }`}>
                                                        <h4 style={{ fontSize: "14px" }}>{item.name}</h4>
                                                        <p>{item.position}</p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                }

                                {showDeputyRegistrar &&
                                    <div className="row no-gutters">
                                        <h4>DEPUTY CHIEF REGISTRAR</h4>
                                        <hr />
                                        {division.deputy_registrars.length > 0 ? division.deputy_registrars.map((item, index) =>
                                            <div key={index} className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                                                <div className="attorney-card">
                                                    <Link to={`/dp-staff/${item.id}`}>
                                                        <img className='mx-auto img2' src={loadDocs(item.image)} alt="Image" />
                                                    </Link>
                                                    <div className="attorney-card-text">
                                                        <h4 style={{ fontSize: "14px" }}><Link to={`/dp-staff/${item.id}`}>{item.name}</Link></h4>

                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                        }

                                    </div>
                                }

                                {showManagement &&
                                    <div className="row no-gutters">
                                        <h4>MANAGEMENT</h4>
                                        <hr />
                                        {division.management.length > 0 ? division.management.map((item, index) =>
                                            <div key={index} className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                                                <div className="attorney-card">
                                                    <Link to={`/management-staff/${item.id}`}>
                                                        <img className='mx-auto img2' src={loadDocs(item.image)} alt="Image" />
                                                    </Link>
                                                    <div className="attorney-card-text">
                                                        <h4 style={{ fontSize: "14px" }}><Link to={`/management-staff/${item.id}`}>{item.name}</Link></h4>
                                                        <p>{item.position}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                        }

                                    </div>
                                }
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-12'>
                                <div className="side-bar-box categories-box">
                                    <h3 className="title">Links</h3>
                                    <ul>
                                        <li><a href="javascript:void(0);" onClick={() => toggleTrueCondition(setShowPJustices)}><i className="las la-angle-double-right"></i> Presiding Justice</a></li>
                                        <li><a href="javascript:void(0);" onClick={() => toggleTrueCondition(setShowJustices)}><i className="las la-angle-double-right"></i> Hon. Justices</a></li>

                                        <li><a href="javascript:void(0);" onClick={() => toggleTrueCondition(setShowDeputyRegistrar)}><i className="las la-angle-double-right"></i> Deputy Chief Registrar</a></li>
                                        <li><a href="javascript:void(0);" onClick={() => toggleTrueCondition(setShowManagement)}><i className="las la-angle-double-right"></i> Management</a></li>
                                        <li><a href="javascript:void(0);" onClick={() => toggleTrueCondition(setShowDirectorate)}><i className="las la-angle-double-right"></i> Directorate</a></li>
                                        <li><Link to={`/judgment?division=${division.id}`}><i className="las la-angle-double-right"></i> Judgments</Link></li>
                                        <li><Link to={`/cause-list?division=${division.id}`}><i className="las la-angle-double-right"></i> Causelist</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default DivisionDetails