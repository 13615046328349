
import React, { useEffect, useState } from 'react';
import { $http } from "../../instance/axios.instance"
import HeroHeader from '../../components/header/useHeroHeader';
import { useParams } from 'react-router-dom';
import { appendScript } from '../../utils/appendScript'
import { formatDate, loadDocs } from "../../utils/utils"
import parse from 'html-react-parser';


function DirectorateDetails() {
    let params = useParams();
    const id = params.id;
    const [staff, setStaff] = useState({});
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        appendScript("assets/js/custom.js");
        async function fetchData() {
            await LoadData();
        };
        fetchData();
    }, [params.id]);

    async function LoadData() {
        try {
            if (id === null || undefined || "") {
                return;
            }
            setLoading(true)
            const response = await $http.get(`directorate-cadres?pr=true`);
            const val = response.data.Headquaters.map((item,index) => {
                item.CurrentIndex = index
                item.Previous = index > 0 ? index - 1 : "";
                item.Next = index < response.data.Headquaters.length-1 ? index + 1 : ""
                return item;
            });
           
            if(val.length > 0)
            {
                setList(val)
                setStaff(val[id]);
                SetData(id);
            }
            setLoading(false)
        }
        catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    function SetData(index){
        if(list.length > 0 && index !=="")
        {
            setStaff(list[index]);
        }
    }


    return (
        <>

            <HeroHeader name={staff.Name} />
            <div className="attorney-details pt-100 pb-70">
                <div className="container faq-area">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="attor-details-item">
                                <img src={loadDocs(staff.Image)} alt="Image" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="attor-details-item">
                                <div className="attor-details-right">
                                    <div className="attor-details-name">
                                        <h2>{staff.Name}</h2>
                                        <table className="table mt-3 table-striped">
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Position</td>
                                                    <td>{staff.Position}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <h4>Profile</h4>
                                                        {
                                                            parse(staff.Profile || "")
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {!loading ?
                                    <div className="col-lg-12 col-md-12">
                                        <ul className="pagination">
                                        <li className="page-item">
                                                        <a className="page-link" href="#" onClick={() => SetData(staff.Previous)} aria-label="Previous">
                                                            <span aria-hidden="true">«</span>
                                                        </a>
                                                    </li>
                                            {
                                                staff.Previous && staff.Previous !== "" ?

                                                <>
                                                   
                                                    <li className="page-item"><a className="page-link" onClick={() => SetData(staff.Previous)} href="#">{staff.Previous + 1 }</a></li>
                                                    </>
                                                    :null
                                            }

                                            <li className="page-item active"><a className="page-link" href="#">{staff.CurrentIndex + 1}</a></li>

                                            {staff.Next &&
                                                <>
                                                    <li className="page-item"><a className="page-link" onClick={() => SetData(staff.Next)} href="#">{staff.CurrentIndex + 2}</a></li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" onClick={() => SetData(staff.Next)} aria-label="Next">
                                                            <span aria-hidden="true">»</span>
                                                        </a>
                                                    </li>
                                                </>}
                                        </ul>
                                    </div>
                                    : <h2 className='loading_center'>...please wait</h2>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default DirectorateDetails;