import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function useHeroHeader({name}){

    useEffect(() => {
        document.title = `Nigeria Court of Appeal: ${name}`;
    });

  return(
    <>
       <div className="page-banner bg-1">
          <div className="d-table">
              <div className="d-table-cell">
                  <div className="container">
                      <div className="page-content">
                          <h2>{name}</h2>
                          <ul>
                              <li><Link to="/">Home <i className="las la-angle-right"></i></Link></li>
                              <li>{name}</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};

export default useHeroHeader;