import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import HeroHeader from '../../components/header/useHeroHeader';
import {$http} from "../../instance/axios.instance"
import TwoSided from '../../components/others/two-sided';
import {formatDate,loadDocs} from "../../utils/utils"
import parse from 'html-react-parser';

const NewsDetails = () => {
	let params = useParams();
	const id = params.id;
	const [obj, setObj] = useState({image: { url:""}});
	const [loading, setLoading] = useState(false);

    useEffect(()=>{
        async function fetchData(){
            try{
				setLoading(true)
				setObj({image: { url:""}});
                const response =  await $http.get(`posts/${id}`);
				let spData = response.data;
                setObj(spData);
				document.title = spData.title;
				setLoading(false)
            }
            catch(e){
                console.log(e)
				setLoading(false)
            }
        };
        fetchData();
    },[params.id]);

	return (
		<>
			<HeroHeader name={obj.title} />
			<div className="services-details-area">
				<TwoSided>
					{!loading ? 
					<div className="services-details">
						<div className="img">
							<img src={loadDocs(obj.image.url)} alt="Image" />
						</div>
						<div className="services-details-content">
							<h3>{obj.title}</h3>
							<ul className="blog-list">
								<li>
									<i className="las la-calendar"></i>
									{formatDate(obj.date)}
								</li>
								<li>
									<i className="las la-user-tie"></i>
									<a href="#">{obj.author}</a>
								</li>
							</ul>
							<p className="align-text-item">
								{parse(obj.content || "")}
							</p>
						</div>
					</div>
					:
					<h2 className='loading_center'>...loading</h2>
					}
				</TwoSided>
			</div>
		</>
	);
}

export default NewsDetails