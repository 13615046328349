import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useAuth } from '../../context/user/user.context';

const Menu = () => {
    const { user, logout } = useAuth();
    return (
        <>
            <div className="atorn-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link className="navbar-brand" to="/">
                            <img src="assets/img/logo.png" className="logo1" alt="logo" />
                            <img src="assets/img/logo-white.png" className="logo2" alt="logo" />
                        </Link>

                        <div className="collapse navbar-collapse mean-menu">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>

                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        About <i className="las la-angle-down"></i>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link to="/judicial-arm" className="nav-link">Judicial Arm</Link></li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                Court History <i className="las la-angle-right"></i>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item"><Link to="/history" className="nav-link">Brief History</Link></li>
                                                <li className="nav-item"><Link to="/administrative-structure" className="nav-link">Administrative Structure</Link></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item"><Link to="/jurisdiction" className="nav-link">Jurisdiction</Link></li>
                                        <li className="nav-item"><Link to="/pioneer-president" className="nav-link">Pioneer President</Link></li>
                                        <li className="nav-item"><Link to="/former-presidents" className="nav-link">Former Presidents of the Court</Link></li>

                                        <li className="nav-item"><Link to="/former-justices" className="nav-link">Hon. Justices Since 1976</Link></li>

                                        <li className="nav-item"><Link to="/current-justices" className="nav-link">Current Justices</Link></li>
                                        <li className="nav-item"><Link to="/justice-posting" className="nav-link">Posting of Justices</Link></li>
                                        <li className="nav-item"><Link to="/seniority-list" className="nav-link">Seniority List of Hon. Justices</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        Management <i className="las la-angle-down"></i>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link to="/structure" className="nav-link">Structure of the Court</Link></li>
                                        <li className="nav-item"><Link to="/past-chief-registrar-court-of-appeal" className="nav-link">Past and Present Chief Registrars</Link></li>

                                        <li className="nav-item"><Link to="/deputy-registrar" className="nav-link">Deputy Chief Registrars</Link></li>
                                        <li className="nav-item"><Link to="/management-staff" className="nav-link">Management</Link></li>
                                        <li className="nav-item"><Link to="/directorate-hq" className="nav-link">Directorate Cadre HQ</Link></li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="/divisions" className="nav-link">Court Divisions</Link></li>



                                <li className="nav-item"><Link to="/documents" className="nav-link">Court Documents</Link></li>
                                {user ?
                                    <li className="nav-item">
                                        <a href="#" className="nav-link">
                                            Admin  <i className="las la-angle-down"></i>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><Link to="/admin/view-judgment" className="nav-link">Judgments</Link></li>
                                            <li className="nav-item"><Link to="/admin/view-causelist" className="nav-link">Causelist</Link></li>
                                        </ul>
                                    </li>
                                    : <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        Litigation  <i className="las la-angle-down"></i>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link to="/judgment" className="nav-link">Judgments</Link></li>
                                        <li className="nav-item"><Link to="/cause-list" className="nav-link">Causelist</Link></li>
                                    </ul>
                                </li>}

                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        Media <i className="las la-angle-down"></i>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link to="/news" className="nav-link">News and Events</Link></li>
                                        <li className="nav-item"><Link to="/picture-category" className="nav-link">Picture Gallery</Link></li>
                                        <li className="nav-item"><a href="https://www.youtube.com/channel/UCW3tbN0XuoX5CwAOLjTbBfg" target={'_blank'} className="nav-link">Videos</a></li>
                                    </ul>
                                </li>

                                {user ?
                                    <li className="nav-item"><a href='javascript:;'  className="nav-link" onClick={logout}>Logout</a></li>
                                    :
                                    <>
                                        <li className="nav-item"><Link to="/faq" className="nav-link">FAQ</Link></li>
                                        <li className="nav-item"><Link to="/contact" className="nav-link">Contact Us</Link></li>
                                    </>
                                }
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}

export { Menu };