import React from 'react';
import RightSideBar from "../others/right-sidebar"


const TwoSided = (props)=> {
      return (
        <>
          <div className="container ptb-100">
            <div className="row">
                <div className="col-lg-8 col-md-7 col-sm-12">
                    <main>{props.children}</main>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-12">
                    <RightSideBar/>
                </div>
            </div>
        </div>
  
        </>
      )
  }
  export default TwoSided;