
import React, { createContext, useContext, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { $http } from '../../instance/axios.instance';
import { reducer, initialState } from "../userReducer"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const UserContext = createContext({
  state: initialState,
  dispatch: () => null
})

export const UserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const navigate = useNavigate();
  const [user, setUser] = useState(null)
  let [searchParams] = useSearchParams();

  const login = async ({ email, password }) => {
    return await $http.post('/auth/local', {
      identifier: email,
      password: password
    }).then(async (x) => {
      const res = x.data;
      localStorage.setItem("token", res.jwt);
      await fetchUser(res.user.id)
      return true;
    }).catch(e => {
      alert(e.response.data.error.message)
      return false;
    });

  };

  const fetchUser = async (userId) => {
    await $http.get(`/users/${userId}?populate[division][populate]=*`,{
      headers: {
        'Authorization': `bearer ${localStorage.getItem("token")}`
      }
    }).then((res) => {
      if (res.status === 200) {
        setUser(res.data)
        dispatch({ type: 'SET_USER', user: res.data });
      }
    });
  };

  const redirectNow = () => {
    navigate("/admin/view-causelist")
  }

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null)
    navigate('/login');
  };

  const token = localStorage.getItem("token");

  const value = {
    login,
    logout,
    token,
    user,
    redirectNow
  };


  return (
    <UserContext.Provider value={value}>
      {children}
      <ToastContainer />
    </UserContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(UserContext)
};