import { Link } from 'react-router-dom';

function section({ showTermsAgreement, subscribeNewsletter }) {
    return (
        <>
            <div className={!showTermsAgreement ? "contact-area ptb-100 case-study-area" : " contact-area contact-two ptb-100"}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-6">
                            <div className="service-card mission">
                                <div className="service-text">
                                    <i className="las la-users "></i>
                                    <h3><a href="#"><span>Court Divisions</span></a></h3>
                                    <p>
                                        The Court of Appeal of Nigeria now boasts of 20 Divisions spread across the Six Geopolitical Zones of Nigeria. Click the button below to learn more about our divisions.
                                        <p>
                                    <Link to="/divisions" className="default-btn-one">
                                    Click here for more
                                    </Link>
                                    </p>


                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="section-title">
                                <span>Contact Form</span>
                                <h2>Send us a message</h2>
                            </div>
                            <div className="contact-form">
                                <form id="contactForm">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="name" className="form-control" id="name" required data-error="Please enter your name" placeholder="Full name" />
                                                <div className="help-block with-errors"></div>
                                                <i className="las la-user"></i>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="email" name="email" className="form-control" id="email" required data-error="Please enter your email" placeholder="Email address" />
                                                <div className="help-block with-errors"></div>
                                                <i className="las la-envelope"></i>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="email" className="form-control" id="Phone" required data-error="Please enter your phone" placeholder="Phone No" />
                                                <div className="help-block with-errors"></div>
                                                <i className="las la-phone"></i>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="email" className="form-control" id="subject" required data-error="Please enter your subject" placeholder="Subject" />
                                                <div className="help-block with-errors"></div>
                                                <i className="las la-id-card"></i>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" id="message" className="form-control" cols="30" rows="6" required data-error="Please enter your message" placeholder="Write your message..."></textarea>
                                                <div className="help-block with-errors"></div>
                                                <i className="las la-sms"></i>
                                            </div>
                                        </div>
                                        {
                                            showTermsAgreement &&
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="form-check agree-label">
                                                        <input
                                                            name="gridCheck"
                                                            value="I agree to the terms and privacy policy."
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="gridCheck"
                                                            required
                                                        />

                                                        <label className="form-check-label" for="gridCheck">
                                                            Accept <a href="#">Terms & Conditions</a> and &nbsp;
                                                            <a href="#">Privacy Policy.</a>
                                                        </label>
                                                        <div className="help-block with-errors gridCheck-error"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            subscribeNewsletter &&
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="form-check agree-label">
                                                        <input
                                                            name="gridCheck"
                                                            value="Subscribe"
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="gridCheck"
                                                            required
                                                        />

                                                        <label className="form-check-label" for="gridCheck">
                                                            Subscribe to our newsletter
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                        <div className="col-lg-12 col-md-12">
                                            <button type="button" className="default-btn-one">Send Message</button>
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default section;