
import React, { useEffect, useState } from 'react';
import { $http } from "../../instance/axios.instance"
import HeroHeader from '../../components/header/useHeroHeader';
import { appendScript } from '../../utils/appendScript'
import { useParams } from 'react-router-dom';
import {formatDate,loadDocs} from "../../utils/utils"
import parse from 'html-react-parser';


function ManagementStaffDetails() {
    let params = useParams();
    const id = params.id;
    const [staff, setStaff] = useState({ Image: {} });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        appendScript("assets/js/custom.js");
        async function fetchData() {
            await LoadData(id);
        };
        fetchData();
    }, [params.id]);

    async function LoadData(staff_id) {
        try {
            if (id === null || undefined || "") {
                return;
            }
            setLoading(true)
            const response = await $http.get(`management-staffs/${staff_id}`);
            setStaff(response.data);
            setLoading(false)
        }
        catch (e) {
            console.log(e)
            setLoading(false)
        }
    }


    return (
        <>

            <HeroHeader name={staff.Name} />
            <div className="attorney-details pt-100 pb-70">
                <div className="container faq-area">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="attor-details-item">
                                <img src={loadDocs(staff.Image.url)} alt="Image" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="attor-details-item">
                                <div className="attor-details-right">
                                    <div className="attor-details-name">
                                        <h2>{staff.Name}</h2>
                                        <table className="table mt-3 table-striped">
                                            <tbody>
                                                <tr>
                                                    <td scope="row">Position</td>
                                                    <td>{staff.Role}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Gender</td>
                                                    <td>{staff.Gender}</td>
                                                </tr>

                                                <tr>
                                                    <td scope="row">Date Of Birth</td>
                                                    <td>{formatDate(staff.DateOfBirth)}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">LG</td>
                                                    <td>{staff.LGA}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">State Of Origin</td>
                                                    <td>{staff.StateOfOrigin}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Date Of Appointment</td>
                                                    <td>{formatDate(staff.DateOfAppointment)}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                    <h4>Institution Attended</h4>
                                                    {
                                                        parse(staff.InstitutionAttended  || "")
                                                    }
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {!loading ?
                                <div className="col-lg-12 col-md-12">
                                    <ul className="pagination">

                                        {
                                            staff.Previous &&

                                            <>
                                                <li className="page-item">
                                                    <a className="page-link" href="#" onClick={async () => await LoadData(staff.Previous)} aria-label="Previous">
                                                        <span aria-hidden="true">«</span>
                                                    </a>
                                                </li>
                                                <li className="page-item"><a className="page-link" onClick={async () => await LoadData(staff.Previous)} href="#">{staff.CurrentIndex}</a></li></>
                                        }

                                        <li className="page-item active"><a className="page-link" href="#">{staff.CurrentIndex + 1}</a></li>

                                        {staff.Next &&
                                            <>
                                                <li className="page-item"><a className="page-link" onClick={async () => await LoadData(staff.Next)} href="#">{staff.CurrentIndex + 2}</a></li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#" onClick={async () => await LoadData(staff.Next)} aria-label="Next">
                                                        <span aria-hidden="true">»</span>
                                                    </a>
                                                </li>
                                            </>}
                                    </ul>
                                </div>
                                : <h2 className='loading_center'>...please wait</h2>
}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ManagementStaffDetails;