import React from 'react';
function header() {
    return (
        <>
            <div className="top-header pt-2 pb-2">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-6">
                            <ul className="left-info">
                                <li>
                                    <a href="mailto:hq@courtofappeal.gov.ng">
                                        <i className="las la-envelope"></i>
                                        hq@courtofappeal.gov.ng
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:+2349010331588">
                                        <i className="las la-phone"></i>
                                        +2349010331588
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <ul className="right-info">
                                <li>
                                    <a href="https://web.facebook.com/CourtofAppealNigeria" target="_blank">
                                        <i className="lab la-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/ngcourtofappeal?s=21&t=hNDI8br9whROkupiQ4v9qw" target="_blank">
                                        <i className="lab la-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/ngcourtofappeal?igshid=YmMyMTA2M2Y=" target="_blank">
                                        <i className="lab la-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCW3tbN0XuoX5CwAOLjTbBfg" target="_blank">
                                        <i className="lab la-youtube"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default header